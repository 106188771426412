<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
/**
 * Form validation component
 */
export default {
  page: {
    title: "Bons Plans",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, ckeditor: CKEditor.component },
  data() {
    return {
      title: "Bons plans",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      bonPlanForm: {
        titre: "",
        soustitre: "",
        url_bonPlan: "",
        image: "",
        visible: true,
        fileaudio: null,
      },
      bonPlanFormModif: {
        id: "",
        titre: "",
        soustitre: "",
        url_bonPlan: "",
        image: "",
        visible: "",
      },
      selectedMedia: "audio",
      bonPlanRowInfo: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      bonPlan: [],
      bonPlanActif: [],
      bonPlanDes: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "titre", sortable: true, label: "Titre" },
        { key: "date", sortable: true },
        { key: "adminId", sortable: true, label: "Publié par" },

        { key: "action" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showactive: false,
      editor: ClassicEditor,
      totalBonPlan: 0,
      totalBonPlanInactif: 0,
      audioLink:null

    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      const listBonPlan = await apiRequest("GET", "bonplans", undefined, false);

      if (listBonPlan && listBonPlan.data) {
        const bonplanFormatTable = listBonPlan.data.plan.map((plan) => {
          return {
            titre: plan.titre,
            id: plan.id,
            url: plan.bonPlanLink,
            soustitre: plan.sousTitre,
            visible: plan.visible,
            date: new Date(plan.dateCreation).toLocaleString("fr-FR"),
            adminId:
              plan.admin != null
                ? plan.admin.firstname + " " + plan.admin.lastname
                : "",
          };
        });
        this.bonPlan = bonplanFormatTable;
        this.bonPlanDes = [];
        this.bonPlanActif = [];

        for (let i = 0; i < this.bonPlan.length; i++) {
          if (this.bonPlan[i].visible == true) {
            this.bonPlanActif.push(this.bonPlan[i]);
            this.totalBonPlan = this.bonPlanActif.length;
          } else {
            this.bonPlanDes.push(this.bonPlan[i]);
            this.totalBonPlanInactif = this.bonPlanDes.length;
          }
        }
      }
    },
    editRow(row) {
      this.showEdit = true;
      this.bonPlanFormModif.titre = row.titre;
      this.bonPlanFormModif.url_bonPlan = row.url;
      this.bonPlanFormModif.soustitre = row.soustitre;
      this.bonPlanFormModif.id = row.id;
      this.bonPlanFormModif.visible = row.visible;
    },
    desactiverRow(row) {
      this.showdisable = true;
      this.bonPlanRowInfo = row;
    },
    activerPlan(row) {
      this.showactive = true;
      this.bonPlanRowInfo = row;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.bonPlanRowInfo = row;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.formSubmit();
      this.$v.$touch();
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    handleFileUploadModif() {
      this.fileModif = this.$refs.files.files[0];
    },

    handleFileUploads() {
      this.audioLink=""
      this.fileaudio =  this.$refs.audioInput.files[0];
      const allowedTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];
      const fileType = this.fileaudio.type;

      if (!allowedTypes.includes(fileType)) {
        // Display an error message or perform some action when an invalid file type is selected.
        console.error("Invalid file type");
        return;
      }

      // File is valid, proceed with further actions.
     
    },
    async submitBonPlanForm() {
     
    /*   formData.append("audioLink", ""); */
    if(this.fileaudio!=null){
     
      this.uploadImage(this.fileaudio)
    }
    else{
      let formData = new FormData();
      formData.append("titre", this.bonPlanForm.titre);
      formData.append("sousTitre", this.bonPlanForm.soustitre);
      formData.append("bonPlanLink", this.bonPlanForm.url_bonPlan);
      formData.append("visible", this.bonPlanForm.visible);
      formData.append("file", this.file);
      formData.append("audioLink", this.audioLink);
      const returnBonPlan = await  apiRequest("POST", "bonplan-ajout", formData, true);

      if (returnBonPlan && returnBonPlan.data) {
       

          this.$toast.success("Bon plan créé avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
        this.init();
      } 

      else{
      
          this.$toast.success("Bon plan non créé", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      }
    }







   
    },
    async submitBonPlanFormModif() {
      let formData = new FormData();
      formData.append("id", this.bonPlanFormModif.id);
      formData.append("titre", this.bonPlanFormModif.titre);
      formData.append("sousTitre", this.bonPlanFormModif.soustitre);
      formData.append("bonPlanLink", this.bonPlanFormModif.url_bonPlan);
      formData.append("file", this.files);
      formData.append("visible", this.bonPlanFormModif.visible);

      const returnBonPlan = await apiRequest("PUT", "bonplan", formData, true);

      if (returnBonPlan && returnBonPlan.data) {
        this.init();
      }
    },
    async desactivePlan() {
      this.showdisable = false;
      const returnBonPlan = await apiRequest(
        "POST",
        "bonplan-off",
        { id: this.bonPlanRowInfo.id, visible: false },
        false
      );

      if (returnBonPlan && returnBonPlan.data) {
        this.init();
      }
    },
    async activePlan() {
      this.showactive = false;
      const returnBonPlan = await apiRequest(
        "POST",
        "bonplan-off",
        { id: this.bonPlanRowInfo.id, visible: true },
        false
      );

      if (returnBonPlan && returnBonPlan.data) {
        this.init();
      }
    },
    async deletePlan() {
      this.showDelete = false;

      const returnBonPlan = await apiRequest(
        "POST",
        "bonplan-supp",
        { id: this.bonPlanRowInfo.id },
        false
      );
      if (returnBonPlan && returnBonPlan.data) {
        this.init();
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
       uploadImage(file) {
  const formData = new FormData();
  formData.append('audio', file);

  // Make an HTTP POST request to the server-side script
  axios.post('https://images.raynis.co/upload.php', formData)
    .then(response => {
      // Handle the response from the server

      this.audioLink=response.data;

     
      let formData = new FormData();
      formData.append("titre", this.bonPlanForm.titre);
      formData.append("sousTitre", this.bonPlanForm.soustitre);
      formData.append("bonPlanLink", this.bonPlanForm.url_bonPlan);
      formData.append("visible", this.bonPlanForm.visible);
      formData.append("file", this.file);
      formData.append("audioLink", this.audioLink);
      const returnBonPlan =  apiRequest("POST", "bonplan", formData, true);

      if (returnBonPlan && returnBonPlan.data) {
        this.init();
      } 
    })
    .catch(error => {
      // Handle any errors that occurred during the upload
      console.error(error);
    });
},
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form
              enctype="multipart/form-data"
              @submit.prevent="submitBonPlanForm"
            >
              <div class="form-group">
                <label for="tite">Titre</label>
                <input
                  type="text"
                  id="title"
                  class="form-control"
                  placeholder="Titre"
                  v-model="bonPlanForm.titre"
                />
              </div>
              <div class="form-group">
                <label for="tite">Texte</label>
                <!--<input
                  type="text"
                  id="title"
                  class="form-control"
                  placeholder="Sous-titre"
                  v-model="bonPlanForm.soustitre"
                />-->
                <ckeditor
                  v-model="bonPlanForm.soustitre"
                  :editor="editor"
                  aria-placeholder="Sous-titre"
                ></ckeditor>
              </div>
              <div class="form-group">
                <label for="tite">Lien du bon plan</label>
                <input
                  type="url"
                  id="title"
                  class="form-control"
                  placeholder="Lien du bon plan"
                  v-model="bonPlanForm.url_bonPlan"
                />
              </div>

              <fieldset>
                <legend>Media</legend>

                <label>
                  <input type="radio" value="audio" v-model="selectedMedia" />
                  Audio
                </label>
                <label class="ml-3">
                  <input type="radio" value="image" v-model="selectedMedia" />
                  Image
                </label>
                <div class="form-group" v-if="(selectedMedia == 'image')">
                  <label for="tite">Image</label>
                  <input
                    type="file"
                    id="tite"
                    ref="file"
                    v-on:change="handleFileUpload"
                    class="form-control"
                  />
                </div>
                <div class="form-group" v-if="(selectedMedia == 'audio')">
                  <label for="tito">Fichier audio</label>
                  <input
                    type="file"
                    ref="audioInput"
                    id="tito"
                    @change="handleFileUploads"
                    class="form-control"
                  />
                </div>
              </fieldset>

              <div class="form-group">
                <button class="btn btn-primary float-right">Ajouter</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Gérer</h5>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des bons plans</a>
                  </template>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                      >
                        <label class="d-inline-flex align-items-center">
                          Rechercher:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="bonPlanActif"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class="text-primary"
                          v-b-tooltip.hover
                          @click="editRow(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="desactiverRow(row.item)"
                          title="Désactiver"
                        >
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="deleteRow(row.item)"
                          v-b-tooltip.hover
                          title="supprimer"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalBonPlan"
                            :per-page="perPage"
                            :rows="bonPlanActif"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                </b-tab>

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active"
                      >Liste des bons plans désactivés</a
                    >
                  </template>
                  <div
                    class="row d-flex justify-content-between align-items-center mt-4"
                  >
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                      <div class="input">
                        <input
                          type="search"
                          id="serchInput"
                          placeholder="Rechercher..."
                          v-model="filter"
                        />
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="bonPlanDes"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class="text-primary"
                          v-b-tooltip.hover
                          @click="editRow(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="activerPlan(row.item)"
                          title="Activer"
                        >
                          <i class="mdi mdi-check font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="deleteRow(row.item)"
                          v-b-tooltip.hover
                          title="supprimer"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalBonPlanInactif"
                            :per-page="perPage"
                            :rows="bonPlanDes"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer ce plan ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletePlan"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment désactiver ce bon plan ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactivePlan"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showactive"
      title="Voulez-vous vraiment activer bon plan ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showactive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activePlan"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer utilisateur"
      title-class="font-18"
      hide-footer
    >
      <form
        enctype="multipart/form-data"
        @submit.prevent="submitBonPlanFormModif"
      >
      {{selectedMedia}}
        <div class="form-group">
          <label for="tite">Titre</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="titre"
            v-model="bonPlanFormModif.titre"
          />
        </div>
        <div class="form-group">
          <label for="tite">Sous titre</label>
          />
        </div>
        <div class="form-group">
          <label for="tite">texte</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="sous titre"
            v-model="bonPlanFormModif.soustitre"
          />
        </div>
        <div>

          <label  form="audio">
            <input id="audio" type="radio"  value="audio" v-model="selectedMedia" />
            Audio
          </label>
          <label for="images">
            <input id="images" type="radio"  value="image" v-model="selectedMedia" />
            Image
          </label>
        </div>
        <div class="form-group">
          <label for="tite">Lien du bon plan</label>
          <input
            type="url"
            id="title"
            class="form-control"
            placeholder="lien du bon plan"
            v-model="bonPlanFormModif.url_bonPlan"
          />
        </div>
        <div class="form-group" v-if="selectedMedia == 'image'">
          <label for="title">Image</label>
          <input
            type="file"
            id="files"
            ref="files"
            v-on:change="handleFileUploadModif"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary float-right">Modifier</button>
          placeholder="titre" />
        </div>
        <div class="form-group">
          <label for="tite">image</label>
          <input type="file" id="title" class="form-control" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary float-right">Ajouter</button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}

thead {
  background: #3aaa35;
}
thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}
.paging_simple_numbers ul li {
  color: white !important;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}


.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}
.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff;
  font-weight: 600 !important;
  vertical-align: middle;
}
.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35;
  border-bottom: 1px solid #fff;
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000;
}
</style>
